<template>
  <v-navigation-drawer
    v-model="show"
    class="nav-drawer"
    app
    dark
    temporary
    width="100%"
  >
    <div class="nav-list-container">
      <div class="close-btn-container">
        <v-btn
          icon
          width="15"
          height="15"
          @click="$emit('close')"
        >
          <v-icon size="15">{{close_btn}}</v-icon>
        </v-btn>
      </div>
      <v-list class="nav-list">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :disabled="!item.active"
          @click="goToRoute(item.routeName)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="title-2"
              :class="{'white--text': item.active, 'inactive-link': !item.active}"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <template v-slot:append>
      <v-btn
        v-if="isUserLoggedIn"
        text
        block
        class="logout-btn"
        @click="logoutUser"
      >
        Log out
      </v-btn>

      <v-btn
        v-else
        text
        block
        class="logout-btn"
        @click="openSignInModal"
      >
        Sign in / Register
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'NavigationDrawer',
    props: ["visible"],
    computed: {
      ...mapGetters(["isUserLoggedIn", "cart", "addressList", "selectedAddress"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      items() {
        let result = [
          {title: 'Menu of the day', routeName: 'menu', active: true},
          {title: 'Favourites', routeName: 'my_favourites', active: true},
          {title: 'My Orders', routeName: 'my_orders', active: false},
          {title: 'My Details', routeName: 'my_details', active: false},
          {title: 'Support', routeName: 'support', active: true}
        ];

        if (this.isUserLoggedIn) {
          result = [
            {title: 'Menu of the day', routeName: 'menu', active: true},
            {title: 'Favourites', routeName: 'my_favourites', active: true},
            {title: 'My Orders', routeName: 'my_orders', active: true},
            {title: 'My Details', routeName: 'my_details', active: true},
            {title: 'Support', routeName: 'support', active: true}
          ];
        }
        return result;
      }
    },
    data: () => ({
      close_btn: "$vuetify.icons.close_btn"
    }),
    methods: {
      ...mapActions([
        "logout",
        "resetOrderObjects",
        "resetProfileObjects",
        "resetMyFavourites"
      ]),
      goToRoute(routeName) {
        if (this.$route.name != routeName) {
          this.$router.push({name: routeName});
        } else {
          this.$emit("close");
        }
      },
      openSignInModal() {
        this.$emit('open-sign-in-modal');
      },
      logoutUser() {
        this.logout().then(() => {
          this.resetMyFavourites().then(() => {
            this.resetOrderObjects().then(() => {
              this.resetProfileObjects().then(() => {
                if (this.$route.name == 'menu') {
                  this.$emit('close');
                  this.$router.go(0);
                } else {
                  this.$router.push({name: 'menu'});
                }
              })
            });
          });
        });
      }
    }
  }
</script>

<style type="text/css">
  #my-app .nav-drawer {
    background-color: #001254;
    z-index: 101 !important;
  }

  #my-app .v-navigation-drawer__content {
    overflow-y: hidden;
  }

  .nav-list-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #my-app .nav-list {
    text-align: center;
  }

  .close-btn-container {
    position: absolute;
    top: 40px;
    right: 30px;
  }

  #my-app .logout-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: initial;
    letter-spacing: 0;
    margin-bottom: 59px;
  }

  .inactive-link {
    color: rgba(255, 255, 255, 0.5);
  }
</style>